import _parsers from "./parsers.js";
import _constants from "./constants.js";
var exports = {};
// autogenerated - 4/29/2020

/*
 *
 * https://www.w3.org/Style/CSS/all-properties.en.html
 */
var external_dependency_parsers_0 = _parsers;
var external_dependency_constants_1 = _constants;
var azimuth_export_definition;
azimuth_export_definition = {
  set: function (v) {
    var valueType = external_dependency_parsers_0.valueType(v);

    if (valueType === external_dependency_parsers_0.TYPES.ANGLE) {
      return this._setProperty("azimuth", external_dependency_parsers_0.parseAngle(v));
    }

    if (valueType === external_dependency_parsers_0.TYPES.KEYWORD) {
      var keywords = v.toLowerCase().trim().split(/\s+/);
      var hasBehind = false;

      if (keywords.length > 2) {
        return;
      }

      var behindIndex = keywords.indexOf("behind");
      hasBehind = behindIndex !== -1;

      if (keywords.length === 2) {
        if (!hasBehind) {
          return;
        }

        keywords.splice(behindIndex, 1);
      }

      if (keywords[0] === "leftwards" || keywords[0] === "rightwards") {
        if (hasBehind) {
          return;
        }

        return this._setProperty("azimuth", keywords[0]);
      }

      if (keywords[0] === "behind") {
        return this._setProperty("azimuth", "180deg");
      }

      switch (keywords[0]) {
        case "left-side":
          return this._setProperty("azimuth", "270deg");

        case "far-left":
          return this._setProperty("azimuth", (hasBehind ? 240 : 300) + "deg");

        case "left":
          return this._setProperty("azimuth", (hasBehind ? 220 : 320) + "deg");

        case "center-left":
          return this._setProperty("azimuth", (hasBehind ? 200 : 340) + "deg");

        case "center":
          return this._setProperty("azimuth", (hasBehind ? 180 : 0) + "deg");

        case "center-right":
          return this._setProperty("azimuth", (hasBehind ? 160 : 20) + "deg");

        case "right":
          return this._setProperty("azimuth", (hasBehind ? 140 : 40) + "deg");

        case "far-right":
          return this._setProperty("azimuth", (hasBehind ? 120 : 60) + "deg");

        case "right-side":
          return this._setProperty("azimuth", "90deg");

        default:
          return;
      }
    }
  },
  get: function () {
    return this.getPropertyValue("azimuth");
  },
  enumerable: true,
  configurable: true
};
var backgroundColor_export_isValid, backgroundColor_export_definition;

var backgroundColor_local_var_parse = function parse(v) {
  var parsed = external_dependency_parsers_0.parseColor(v);

  if (parsed !== undefined) {
    return parsed;
  }

  if (external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.KEYWORD && (v.toLowerCase() === "transparent" || v.toLowerCase() === "inherit")) {
    return v;
  }

  return undefined;
};

backgroundColor_export_isValid = function isValid(v) {
  return backgroundColor_local_var_parse(v) !== undefined;
};

backgroundColor_export_definition = {
  set: function (v) {
    var parsed = backgroundColor_local_var_parse(v);

    if (parsed === undefined) {
      return;
    }

    this._setProperty("background-color", parsed);
  },
  get: function () {
    return this.getPropertyValue("background-color");
  },
  enumerable: true,
  configurable: true
};
var backgroundImage_export_isValid, backgroundImage_export_definition;

var backgroundImage_local_var_parse = function parse(v) {
  var parsed = external_dependency_parsers_0.parseUrl(v);

  if (parsed !== undefined) {
    return parsed;
  }

  if (external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.KEYWORD && (v.toLowerCase() === "none" || v.toLowerCase() === "inherit")) {
    return v;
  }

  return undefined;
};

backgroundImage_export_isValid = function isValid(v) {
  return backgroundImage_local_var_parse(v) !== undefined;
};

backgroundImage_export_definition = {
  set: function (v) {
    this._setProperty("background-image", backgroundImage_local_var_parse(v));
  },
  get: function () {
    return this.getPropertyValue("background-image");
  },
  enumerable: true,
  configurable: true
};
var backgroundRepeat_export_isValid, backgroundRepeat_export_definition;

var backgroundRepeat_local_var_parse = function parse(v) {
  if (external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.KEYWORD && (v.toLowerCase() === "repeat" || v.toLowerCase() === "repeat-x" || v.toLowerCase() === "repeat-y" || v.toLowerCase() === "no-repeat" || v.toLowerCase() === "inherit")) {
    return v;
  }

  return undefined;
};

backgroundRepeat_export_isValid = function isValid(v) {
  return backgroundRepeat_local_var_parse(v) !== undefined;
};

backgroundRepeat_export_definition = {
  set: function (v) {
    this._setProperty("background-repeat", backgroundRepeat_local_var_parse(v));
  },
  get: function () {
    return this.getPropertyValue("background-repeat");
  },
  enumerable: true,
  configurable: true
};
var backgroundAttachment_export_isValid, backgroundAttachment_export_definition;

var backgroundAttachment_local_var_isValid = backgroundAttachment_export_isValid = function isValid(v) {
  return external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.KEYWORD && (v.toLowerCase() === "scroll" || v.toLowerCase() === "fixed" || v.toLowerCase() === "inherit");
};

backgroundAttachment_export_definition = {
  set: function (v) {
    if (!backgroundAttachment_local_var_isValid(v)) {
      return;
    }

    this._setProperty("background-attachment", v);
  },
  get: function () {
    return this.getPropertyValue("background-attachment");
  },
  enumerable: true,
  configurable: true
};
var backgroundPosition_export_isValid, backgroundPosition_export_definition;
var backgroundPosition_local_var_valid_keywords = ["top", "center", "bottom", "left", "right"];

var backgroundPosition_local_var_parse = function parse(v) {
  if (v === "" || v === null) {
    return undefined;
  }

  var parts = v.split(/\s+/);

  if (parts.length > 2 || parts.length < 1) {
    return undefined;
  }

  var types = [];
  parts.forEach(function (part, index) {
    types[index] = external_dependency_parsers_0.valueType(part);
  });

  if (parts.length === 1) {
    if (types[0] === external_dependency_parsers_0.TYPES.LENGTH || types[0] === external_dependency_parsers_0.TYPES.PERCENT) {
      return v;
    }

    if (types[0] === external_dependency_parsers_0.TYPES.KEYWORD) {
      if (backgroundPosition_local_var_valid_keywords.indexOf(v.toLowerCase()) !== -1 || v.toLowerCase() === "inherit") {
        return v;
      }
    }

    return undefined;
  }

  if ((types[0] === external_dependency_parsers_0.TYPES.LENGTH || types[0] === external_dependency_parsers_0.TYPES.PERCENT) && (types[1] === external_dependency_parsers_0.TYPES.LENGTH || types[1] === external_dependency_parsers_0.TYPES.PERCENT)) {
    return v;
  }

  if (types[0] !== external_dependency_parsers_0.TYPES.KEYWORD || types[1] !== external_dependency_parsers_0.TYPES.KEYWORD) {
    return undefined;
  }

  if (backgroundPosition_local_var_valid_keywords.indexOf(parts[0]) !== -1 && backgroundPosition_local_var_valid_keywords.indexOf(parts[1]) !== -1) {
    return v;
  }

  return undefined;
};

backgroundPosition_export_isValid = function isValid(v) {
  return backgroundPosition_local_var_parse(v) !== undefined;
};

backgroundPosition_export_definition = {
  set: function (v) {
    this._setProperty("background-position", backgroundPosition_local_var_parse(v));
  },
  get: function () {
    return this.getPropertyValue("background-position");
  },
  enumerable: true,
  configurable: true
};
var background_export_definition;
var background_local_var_shorthand_for = {
  "background-color": {
    isValid: backgroundColor_export_isValid,
    definition: backgroundColor_export_definition
  },
  "background-image": {
    isValid: backgroundImage_export_isValid,
    definition: backgroundImage_export_definition
  },
  "background-repeat": {
    isValid: backgroundRepeat_export_isValid,
    definition: backgroundRepeat_export_definition
  },
  "background-attachment": {
    isValid: backgroundAttachment_export_isValid,
    definition: backgroundAttachment_export_definition
  },
  "background-position": {
    isValid: backgroundPosition_export_isValid,
    definition: backgroundPosition_export_definition
  }
};
background_export_definition = {
  set: external_dependency_parsers_0.shorthandSetter("background", background_local_var_shorthand_for),
  get: external_dependency_parsers_0.shorthandGetter("background", background_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var borderWidth_export_isValid, borderWidth_export_definition; // the valid border-widths:

var borderWidth_local_var_widths = ["thin", "medium", "thick"];

borderWidth_export_isValid = function parse(v) {
  var length = external_dependency_parsers_0.parseLength(v);

  if (length !== undefined) {
    return true;
  }

  if (typeof v !== "string") {
    return false;
  }

  if (v === "") {
    return true;
  }

  v = v.toLowerCase();

  if (borderWidth_local_var_widths.indexOf(v) === -1) {
    return false;
  }

  return true;
};

var borderWidth_local_var_isValid = borderWidth_export_isValid;

var borderWidth_local_var_parser = function (v) {
  var length = external_dependency_parsers_0.parseLength(v);

  if (length !== undefined) {
    return length;
  }

  if (borderWidth_local_var_isValid(v)) {
    return v.toLowerCase();
  }

  return undefined;
};

borderWidth_export_definition = {
  set: external_dependency_parsers_0.implicitSetter("border", "width", borderWidth_local_var_isValid, borderWidth_local_var_parser),
  get: function () {
    return this.getPropertyValue("border-width");
  },
  enumerable: true,
  configurable: true
};
var borderStyle_export_isValid, borderStyle_export_definition; // the valid border-styles:

var borderStyle_local_var_styles = ["none", "hidden", "dotted", "dashed", "solid", "double", "groove", "ridge", "inset", "outset"];

borderStyle_export_isValid = function parse(v) {
  return typeof v === "string" && (v === "" || borderStyle_local_var_styles.indexOf(v) !== -1);
};

var borderStyle_local_var_isValid = borderStyle_export_isValid;

var borderStyle_local_var_parser = function (v) {
  if (borderStyle_local_var_isValid(v)) {
    return v.toLowerCase();
  }

  return undefined;
};

borderStyle_export_definition = {
  set: external_dependency_parsers_0.implicitSetter("border", "style", borderStyle_local_var_isValid, borderStyle_local_var_parser),
  get: function () {
    return this.getPropertyValue("border-style");
  },
  enumerable: true,
  configurable: true
};
var borderColor_export_isValid, borderColor_export_definition;

borderColor_export_isValid = function parse(v) {
  if (typeof v !== "string") {
    return false;
  }

  return v === "" || v.toLowerCase() === "transparent" || external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.COLOR;
};

var borderColor_local_var_isValid = borderColor_export_isValid;

var borderColor_local_var_parser = function (v) {
  if (borderColor_local_var_isValid(v)) {
    return v.toLowerCase();
  }

  return undefined;
};

borderColor_export_definition = {
  set: external_dependency_parsers_0.implicitSetter("border", "color", borderColor_local_var_isValid, borderColor_local_var_parser),
  get: function () {
    return this.getPropertyValue("border-color");
  },
  enumerable: true,
  configurable: true
};
var border_export_definition;
var border_local_var_shorthand_for = {
  "border-width": {
    isValid: borderWidth_export_isValid,
    definition: borderWidth_export_definition
  },
  "border-style": {
    isValid: borderStyle_export_isValid,
    definition: borderStyle_export_definition
  },
  "border-color": {
    isValid: borderColor_export_isValid,
    definition: borderColor_export_definition
  }
};
var border_local_var_myShorthandSetter = external_dependency_parsers_0.shorthandSetter("border", border_local_var_shorthand_for);
var border_local_var_myShorthandGetter = external_dependency_parsers_0.shorthandGetter("border", border_local_var_shorthand_for);
border_export_definition = {
  set: function (v) {
    if (v.toString().toLowerCase() === "none") {
      v = "";
    }

    border_local_var_myShorthandSetter.call(this, v);
    this.removeProperty("border-top");
    this.removeProperty("border-left");
    this.removeProperty("border-right");
    this.removeProperty("border-bottom");
    this._values["border-top"] = this._values.border;
    this._values["border-left"] = this._values.border;
    this._values["border-right"] = this._values.border;
    this._values["border-bottom"] = this._values.border;
  },
  get: border_local_var_myShorthandGetter,
  enumerable: true,
  configurable: true
};
var borderBottomWidth_export_isValid, borderBottomWidth_export_definition;
var borderBottomWidth_local_var_isValid = borderBottomWidth_export_isValid = borderWidth_export_isValid;
borderBottomWidth_export_definition = {
  set: function (v) {
    if (borderBottomWidth_local_var_isValid(v)) {
      this._setProperty("border-bottom-width", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-bottom-width");
  },
  enumerable: true,
  configurable: true
};
var borderBottomStyle_export_isValid, borderBottomStyle_export_definition;
borderBottomStyle_export_isValid = borderStyle_export_isValid;
borderBottomStyle_export_definition = {
  set: function (v) {
    if (borderStyle_export_isValid(v)) {
      if (v.toLowerCase() === "none") {
        v = "";
        this.removeProperty("border-bottom-width");
      }

      this._setProperty("border-bottom-style", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-bottom-style");
  },
  enumerable: true,
  configurable: true
};
var borderBottomColor_export_isValid, borderBottomColor_export_definition;
var borderBottomColor_local_var_isValid = borderBottomColor_export_isValid = borderColor_export_isValid;
borderBottomColor_export_definition = {
  set: function (v) {
    if (borderBottomColor_local_var_isValid(v)) {
      this._setProperty("border-bottom-color", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-bottom-color");
  },
  enumerable: true,
  configurable: true
};
var borderBottom_export_definition;
var borderBottom_local_var_shorthand_for = {
  "border-bottom-width": {
    isValid: borderBottomWidth_export_isValid,
    definition: borderBottomWidth_export_definition
  },
  "border-bottom-style": {
    isValid: borderBottomStyle_export_isValid,
    definition: borderBottomStyle_export_definition
  },
  "border-bottom-color": {
    isValid: borderBottomColor_export_isValid,
    definition: borderBottomColor_export_definition
  }
};
borderBottom_export_definition = {
  set: external_dependency_parsers_0.shorthandSetter("border-bottom", borderBottom_local_var_shorthand_for),
  get: external_dependency_parsers_0.shorthandGetter("border-bottom", borderBottom_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var borderCollapse_export_definition;

var borderCollapse_local_var_parse = function parse(v) {
  if (external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.KEYWORD && (v.toLowerCase() === "collapse" || v.toLowerCase() === "separate" || v.toLowerCase() === "inherit")) {
    return v;
  }

  return undefined;
};

borderCollapse_export_definition = {
  set: function (v) {
    this._setProperty("border-collapse", borderCollapse_local_var_parse(v));
  },
  get: function () {
    return this.getPropertyValue("border-collapse");
  },
  enumerable: true,
  configurable: true
};
var borderLeftWidth_export_isValid, borderLeftWidth_export_definition;
var borderLeftWidth_local_var_isValid = borderLeftWidth_export_isValid = borderWidth_export_isValid;
borderLeftWidth_export_definition = {
  set: function (v) {
    if (borderLeftWidth_local_var_isValid(v)) {
      this._setProperty("border-left-width", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-left-width");
  },
  enumerable: true,
  configurable: true
};
var borderLeftStyle_export_isValid, borderLeftStyle_export_definition;
borderLeftStyle_export_isValid = borderStyle_export_isValid;
borderLeftStyle_export_definition = {
  set: function (v) {
    if (borderStyle_export_isValid(v)) {
      if (v.toLowerCase() === "none") {
        v = "";
        this.removeProperty("border-left-width");
      }

      this._setProperty("border-left-style", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-left-style");
  },
  enumerable: true,
  configurable: true
};
var borderLeftColor_export_isValid, borderLeftColor_export_definition;
var borderLeftColor_local_var_isValid = borderLeftColor_export_isValid = borderColor_export_isValid;
borderLeftColor_export_definition = {
  set: function (v) {
    if (borderLeftColor_local_var_isValid(v)) {
      this._setProperty("border-left-color", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-left-color");
  },
  enumerable: true,
  configurable: true
};
var borderLeft_export_definition;
var borderLeft_local_var_shorthand_for = {
  "border-left-width": {
    isValid: borderLeftWidth_export_isValid,
    definition: borderLeftWidth_export_definition
  },
  "border-left-style": {
    isValid: borderLeftStyle_export_isValid,
    definition: borderLeftStyle_export_definition
  },
  "border-left-color": {
    isValid: borderLeftColor_export_isValid,
    definition: borderLeftColor_export_definition
  }
};
borderLeft_export_definition = {
  set: external_dependency_parsers_0.shorthandSetter("border-left", borderLeft_local_var_shorthand_for),
  get: external_dependency_parsers_0.shorthandGetter("border-left", borderLeft_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var borderRightWidth_export_isValid, borderRightWidth_export_definition;
var borderRightWidth_local_var_isValid = borderRightWidth_export_isValid = borderWidth_export_isValid;
borderRightWidth_export_definition = {
  set: function (v) {
    if (borderRightWidth_local_var_isValid(v)) {
      this._setProperty("border-right-width", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-right-width");
  },
  enumerable: true,
  configurable: true
};
var borderRightStyle_export_isValid, borderRightStyle_export_definition;
borderRightStyle_export_isValid = borderStyle_export_isValid;
borderRightStyle_export_definition = {
  set: function (v) {
    if (borderStyle_export_isValid(v)) {
      if (v.toLowerCase() === "none") {
        v = "";
        this.removeProperty("border-right-width");
      }

      this._setProperty("border-right-style", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-right-style");
  },
  enumerable: true,
  configurable: true
};
var borderRightColor_export_isValid, borderRightColor_export_definition;
var borderRightColor_local_var_isValid = borderRightColor_export_isValid = borderColor_export_isValid;
borderRightColor_export_definition = {
  set: function (v) {
    if (borderRightColor_local_var_isValid(v)) {
      this._setProperty("border-right-color", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-right-color");
  },
  enumerable: true,
  configurable: true
};
var borderRight_export_definition;
var borderRight_local_var_shorthand_for = {
  "border-right-width": {
    isValid: borderRightWidth_export_isValid,
    definition: borderRightWidth_export_definition
  },
  "border-right-style": {
    isValid: borderRightStyle_export_isValid,
    definition: borderRightStyle_export_definition
  },
  "border-right-color": {
    isValid: borderRightColor_export_isValid,
    definition: borderRightColor_export_definition
  }
};
borderRight_export_definition = {
  set: external_dependency_parsers_0.shorthandSetter("border-right", borderRight_local_var_shorthand_for),
  get: external_dependency_parsers_0.shorthandGetter("border-right", borderRight_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var borderSpacing_export_definition; // <length> <length>? | inherit
// if one, it applies to both horizontal and verical spacing
// if two, the first applies to the horizontal and the second applies to vertical spacing

var borderSpacing_local_var_parse = function parse(v) {
  if (v === "" || v === null) {
    return undefined;
  }

  if (v === 0) {
    return "0px";
  }

  if (v.toLowerCase() === "inherit") {
    return v;
  }

  var parts = v.split(/\s+/);

  if (parts.length !== 1 && parts.length !== 2) {
    return undefined;
  }

  parts.forEach(function (part) {
    if (external_dependency_parsers_0.valueType(part) !== external_dependency_parsers_0.TYPES.LENGTH) {
      return undefined;
    }
  });
  return v;
};

borderSpacing_export_definition = {
  set: function (v) {
    this._setProperty("border-spacing", borderSpacing_local_var_parse(v));
  },
  get: function () {
    return this.getPropertyValue("border-spacing");
  },
  enumerable: true,
  configurable: true
};
var borderTopWidth_export_isValid, borderTopWidth_export_definition;
borderTopWidth_export_isValid = borderWidth_export_isValid;
borderTopWidth_export_definition = {
  set: function (v) {
    if (borderWidth_export_isValid(v)) {
      this._setProperty("border-top-width", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-top-width");
  },
  enumerable: true,
  configurable: true
};
var borderTopStyle_export_isValid, borderTopStyle_export_definition;
borderTopStyle_export_isValid = borderStyle_export_isValid;
borderTopStyle_export_definition = {
  set: function (v) {
    if (borderStyle_export_isValid(v)) {
      if (v.toLowerCase() === "none") {
        v = "";
        this.removeProperty("border-top-width");
      }

      this._setProperty("border-top-style", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-top-style");
  },
  enumerable: true,
  configurable: true
};
var borderTopColor_export_isValid, borderTopColor_export_definition;
var borderTopColor_local_var_isValid = borderTopColor_export_isValid = borderColor_export_isValid;
borderTopColor_export_definition = {
  set: function (v) {
    if (borderTopColor_local_var_isValid(v)) {
      this._setProperty("border-top-color", v);
    }
  },
  get: function () {
    return this.getPropertyValue("border-top-color");
  },
  enumerable: true,
  configurable: true
};
var borderTop_export_definition;
var borderTop_local_var_shorthand_for = {
  "border-top-width": {
    isValid: borderTopWidth_export_isValid,
    definition: borderTopWidth_export_definition
  },
  "border-top-style": {
    isValid: borderTopStyle_export_isValid,
    definition: borderTopStyle_export_definition
  },
  "border-top-color": {
    isValid: borderTopColor_export_isValid,
    definition: borderTopColor_export_definition
  }
};
borderTop_export_definition = {
  set: external_dependency_parsers_0.shorthandSetter("border-top", borderTop_local_var_shorthand_for),
  get: external_dependency_parsers_0.shorthandGetter("border-top", borderTop_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var bottom_export_definition;
bottom_export_definition = {
  set: function (v) {
    this._setProperty("bottom", external_dependency_parsers_0.parseMeasurement(v));
  },
  get: function () {
    return this.getPropertyValue("bottom");
  },
  enumerable: true,
  configurable: true
};
var clear_export_definition;
var clear_local_var_clear_keywords = ["none", "left", "right", "both", "inherit"];
clear_export_definition = {
  set: function (v) {
    this._setProperty("clear", external_dependency_parsers_0.parseKeyword(v, clear_local_var_clear_keywords));
  },
  get: function () {
    return this.getPropertyValue("clear");
  },
  enumerable: true,
  configurable: true
};
var clip_export_definition;
var clip_local_var_shape_regex = /^rect\((.*)\)$/i;

var clip_local_var_parse = function (val) {
  if (val === "" || val === null) {
    return val;
  }

  if (typeof val !== "string") {
    return undefined;
  }

  val = val.toLowerCase();

  if (val === "auto" || val === "inherit") {
    return val;
  }

  var matches = val.match(clip_local_var_shape_regex);

  if (!matches) {
    return undefined;
  }

  var parts = matches[1].split(/\s*,\s*/);

  if (parts.length !== 4) {
    return undefined;
  }

  var valid = parts.every(function (part, index) {
    var measurement = external_dependency_parsers_0.parseMeasurement(part);
    parts[index] = measurement;
    return measurement !== undefined;
  });

  if (!valid) {
    return undefined;
  }

  parts = parts.join(", ");
  return val.replace(matches[1], parts);
};

clip_export_definition = {
  set: function (v) {
    this._setProperty("clip", clip_local_var_parse(v));
  },
  get: function () {
    return this.getPropertyValue("clip");
  },
  enumerable: true,
  configurable: true
};
var color_export_definition;
color_export_definition = {
  set: function (v) {
    this._setProperty("color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("color");
  },
  enumerable: true,
  configurable: true
};
var cssFloat_export_definition;
cssFloat_export_definition = {
  set: function (v) {
    this._setProperty("float", v);
  },
  get: function () {
    return this.getPropertyValue("float");
  },
  enumerable: true,
  configurable: true
};
var flexGrow_export_isValid, flexGrow_export_definition;

flexGrow_export_isValid = function isValid(v, positionAtFlexShorthand) {
  return external_dependency_parsers_0.parseNumber(v) !== undefined && positionAtFlexShorthand === external_dependency_constants_1.POSITION_AT_SHORTHAND.first;
};

flexGrow_export_definition = {
  set: function (v) {
    this._setProperty("flex-grow", external_dependency_parsers_0.parseNumber(v));
  },
  get: function () {
    return this.getPropertyValue("flex-grow");
  },
  enumerable: true,
  configurable: true
};
var flexShrink_export_isValid, flexShrink_export_definition;

flexShrink_export_isValid = function isValid(v, positionAtFlexShorthand) {
  return external_dependency_parsers_0.parseNumber(v) !== undefined && positionAtFlexShorthand === external_dependency_constants_1.POSITION_AT_SHORTHAND.second;
};

flexShrink_export_definition = {
  set: function (v) {
    this._setProperty("flex-shrink", external_dependency_parsers_0.parseNumber(v));
  },
  get: function () {
    return this.getPropertyValue("flex-shrink");
  },
  enumerable: true,
  configurable: true
};
var flexBasis_export_isValid, flexBasis_export_definition;

function flexBasis_local_fn_parse(v) {
  if (String(v).toLowerCase() === "auto") {
    return "auto";
  }

  if (String(v).toLowerCase() === "inherit") {
    return "inherit";
  }

  return external_dependency_parsers_0.parseMeasurement(v);
}

flexBasis_export_isValid = function isValid(v) {
  return flexBasis_local_fn_parse(v) !== undefined;
};

flexBasis_export_definition = {
  set: function (v) {
    this._setProperty("flex-basis", flexBasis_local_fn_parse(v));
  },
  get: function () {
    return this.getPropertyValue("flex-basis");
  },
  enumerable: true,
  configurable: true
};
var flex_export_isValid, flex_export_definition;
var flex_local_var_shorthand_for = {
  "flex-grow": {
    isValid: flexGrow_export_isValid,
    definition: flexGrow_export_definition
  },
  "flex-shrink": {
    isValid: flexShrink_export_isValid,
    definition: flexShrink_export_definition
  },
  "flex-basis": {
    isValid: flexBasis_export_isValid,
    definition: flexBasis_export_definition
  }
};
var flex_local_var_myShorthandSetter = external_dependency_parsers_0.shorthandSetter("flex", flex_local_var_shorthand_for);

flex_export_isValid = function isValid(v) {
  return external_dependency_parsers_0.shorthandParser(v, flex_local_var_shorthand_for) !== undefined;
};

flex_export_definition = {
  set: function (v) {
    var normalizedValue = String(v).trim().toLowerCase();

    if (normalizedValue === "none") {
      flex_local_var_myShorthandSetter.call(this, "0 0 auto");
      return;
    }

    if (normalizedValue === "initial") {
      flex_local_var_myShorthandSetter.call(this, "0 1 auto");
      return;
    }

    if (normalizedValue === "auto") {
      this.removeProperty("flex-grow");
      this.removeProperty("flex-shrink");
      this.setProperty("flex-basis", normalizedValue);
      return;
    }

    flex_local_var_myShorthandSetter.call(this, v);
  },
  get: external_dependency_parsers_0.shorthandGetter("flex", flex_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var float_export_definition;
float_export_definition = {
  set: function (v) {
    this._setProperty("float", v);
  },
  get: function () {
    return this.getPropertyValue("float");
  },
  enumerable: true,
  configurable: true
};
var floodColor_export_definition;
floodColor_export_definition = {
  set: function (v) {
    this._setProperty("flood-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("flood-color");
  },
  enumerable: true,
  configurable: true
};
var fontFamily_export_isValid, fontFamily_export_definition;
var fontFamily_local_var_partsRegEx = /\s*,\s*/;

fontFamily_export_isValid = function isValid(v) {
  if (v === "" || v === null) {
    return true;
  }

  var parts = v.split(fontFamily_local_var_partsRegEx);
  var len = parts.length;
  var i;
  var type;

  for (i = 0; i < len; i++) {
    type = external_dependency_parsers_0.valueType(parts[i]);

    if (type === external_dependency_parsers_0.TYPES.STRING || type === external_dependency_parsers_0.TYPES.KEYWORD) {
      return true;
    }
  }

  return false;
};

fontFamily_export_definition = {
  set: function (v) {
    this._setProperty("font-family", v);
  },
  get: function () {
    return this.getPropertyValue("font-family");
  },
  enumerable: true,
  configurable: true
};
var fontSize_export_isValid, fontSize_export_definition;
var fontSize_local_var_absoluteSizes = ["xx-small", "x-small", "small", "medium", "large", "x-large", "xx-large"];
var fontSize_local_var_relativeSizes = ["larger", "smaller"];

fontSize_export_isValid = function (v) {
  var type = external_dependency_parsers_0.valueType(v.toLowerCase());
  return type === external_dependency_parsers_0.TYPES.LENGTH || type === external_dependency_parsers_0.TYPES.PERCENT || type === external_dependency_parsers_0.TYPES.KEYWORD && fontSize_local_var_absoluteSizes.indexOf(v.toLowerCase()) !== -1 || type === external_dependency_parsers_0.TYPES.KEYWORD && fontSize_local_var_relativeSizes.indexOf(v.toLowerCase()) !== -1;
};

function fontSize_local_fn_parse(v) {
  const valueAsString = String(v).toLowerCase();
  const optionalArguments = fontSize_local_var_absoluteSizes.concat(fontSize_local_var_relativeSizes);
  const isOptionalArgument = optionalArguments.some(stringValue => stringValue.toLowerCase() === valueAsString);
  return isOptionalArgument ? valueAsString : external_dependency_parsers_0.parseMeasurement(v);
}

fontSize_export_definition = {
  set: function (v) {
    this._setProperty("font-size", fontSize_local_fn_parse(v));
  },
  get: function () {
    return this.getPropertyValue("font-size");
  },
  enumerable: true,
  configurable: true
};
var fontStyle_export_isValid, fontStyle_export_definition;
var fontStyle_local_var_valid_styles = ["normal", "italic", "oblique", "inherit"];

fontStyle_export_isValid = function (v) {
  return fontStyle_local_var_valid_styles.indexOf(v.toLowerCase()) !== -1;
};

fontStyle_export_definition = {
  set: function (v) {
    this._setProperty("font-style", v);
  },
  get: function () {
    return this.getPropertyValue("font-style");
  },
  enumerable: true,
  configurable: true
};
var fontVariant_export_isValid, fontVariant_export_definition;
var fontVariant_local_var_valid_variants = ["normal", "small-caps", "inherit"];

fontVariant_export_isValid = function isValid(v) {
  return fontVariant_local_var_valid_variants.indexOf(v.toLowerCase()) !== -1;
};

fontVariant_export_definition = {
  set: function (v) {
    this._setProperty("font-variant", v);
  },
  get: function () {
    return this.getPropertyValue("font-variant");
  },
  enumerable: true,
  configurable: true
};
var fontWeight_export_isValid, fontWeight_export_definition;
var fontWeight_local_var_valid_weights = ["normal", "bold", "bolder", "lighter", "100", "200", "300", "400", "500", "600", "700", "800", "900", "inherit"];

fontWeight_export_isValid = function isValid(v) {
  return fontWeight_local_var_valid_weights.indexOf(v.toLowerCase()) !== -1;
};

fontWeight_export_definition = {
  set: function (v) {
    this._setProperty("font-weight", v);
  },
  get: function () {
    return this.getPropertyValue("font-weight");
  },
  enumerable: true,
  configurable: true
};
var lineHeight_export_isValid, lineHeight_export_definition;

lineHeight_export_isValid = function isValid(v) {
  var type = external_dependency_parsers_0.valueType(v);
  return type === external_dependency_parsers_0.TYPES.KEYWORD && v.toLowerCase() === "normal" || v.toLowerCase() === "inherit" || type === external_dependency_parsers_0.TYPES.NUMBER || type === external_dependency_parsers_0.TYPES.LENGTH || type === external_dependency_parsers_0.TYPES.PERCENT;
};

lineHeight_export_definition = {
  set: function (v) {
    this._setProperty("line-height", v);
  },
  get: function () {
    return this.getPropertyValue("line-height");
  },
  enumerable: true,
  configurable: true
};
var font_export_definition;
var font_local_var_shorthand_for = {
  "font-family": {
    isValid: fontFamily_export_isValid,
    definition: fontFamily_export_definition
  },
  "font-size": {
    isValid: fontSize_export_isValid,
    definition: fontSize_export_definition
  },
  "font-style": {
    isValid: fontStyle_export_isValid,
    definition: fontStyle_export_definition
  },
  "font-variant": {
    isValid: fontVariant_export_isValid,
    definition: fontVariant_export_definition
  },
  "font-weight": {
    isValid: fontWeight_export_isValid,
    definition: fontWeight_export_definition
  },
  "line-height": {
    isValid: lineHeight_export_isValid,
    definition: lineHeight_export_definition
  }
};
var font_local_var_static_fonts = ["caption", "icon", "menu", "message-box", "small-caption", "status-bar", "inherit"];
var font_local_var_setter = external_dependency_parsers_0.shorthandSetter("font", font_local_var_shorthand_for);
font_export_definition = {
  set: function (v) {
    var short = external_dependency_parsers_0.shorthandParser(v, font_local_var_shorthand_for);

    if (short !== undefined) {
      return font_local_var_setter.call(this, v);
    }

    if (external_dependency_parsers_0.valueType(v) === external_dependency_parsers_0.TYPES.KEYWORD && font_local_var_static_fonts.indexOf(v.toLowerCase()) !== -1) {
      this._setProperty("font", v);
    }
  },
  get: external_dependency_parsers_0.shorthandGetter("font", font_local_var_shorthand_for),
  enumerable: true,
  configurable: true
};
var height_export_definition;

function height_local_fn_parse(v) {
  if (String(v).toLowerCase() === "auto") {
    return "auto";
  }

  if (String(v).toLowerCase() === "inherit") {
    return "inherit";
  }

  return external_dependency_parsers_0.parseMeasurement(v);
}

height_export_definition = {
  set: function (v) {
    this._setProperty("height", height_local_fn_parse(v));
  },
  get: function () {
    return this.getPropertyValue("height");
  },
  enumerable: true,
  configurable: true
};
var left_export_definition;
left_export_definition = {
  set: function (v) {
    this._setProperty("left", external_dependency_parsers_0.parseMeasurement(v));
  },
  get: function () {
    return this.getPropertyValue("left");
  },
  enumerable: true,
  configurable: true
};
var lightingColor_export_definition;
lightingColor_export_definition = {
  set: function (v) {
    this._setProperty("lighting-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("lighting-color");
  },
  enumerable: true,
  configurable: true
};
var margin_export_definition, margin_export_isValid, margin_export_parser;
var margin_local_var_TYPES = external_dependency_parsers_0.TYPES;

var margin_local_var_isValid = function (v) {
  if (v.toLowerCase() === "auto") {
    return true;
  }

  var type = external_dependency_parsers_0.valueType(v);
  return type === margin_local_var_TYPES.LENGTH || type === margin_local_var_TYPES.PERCENT || type === margin_local_var_TYPES.INTEGER && (v === "0" || v === 0);
};

var margin_local_var_parser = function (v) {
  var V = v.toLowerCase();

  if (V === "auto") {
    return V;
  }

  return external_dependency_parsers_0.parseMeasurement(v);
};

var margin_local_var_mySetter = external_dependency_parsers_0.implicitSetter("margin", "", margin_local_var_isValid, margin_local_var_parser);
var margin_local_var_myGlobal = external_dependency_parsers_0.implicitSetter("margin", "", function () {
  return true;
}, function (v) {
  return v;
});
margin_export_definition = {
  set: function (v) {
    if (typeof v === "number") {
      v = String(v);
    }

    if (typeof v !== "string") {
      return;
    }

    var V = v.toLowerCase();

    switch (V) {
      case "inherit":
      case "initial":
      case "unset":
      case "":
        margin_local_var_myGlobal.call(this, V);
        break;

      default:
        margin_local_var_mySetter.call(this, v);
        break;
    }
  },
  get: function () {
    return this.getPropertyValue("margin");
  },
  enumerable: true,
  configurable: true
};
margin_export_isValid = margin_local_var_isValid;
margin_export_parser = margin_local_var_parser;
var marginBottom_export_definition;
marginBottom_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("margin", "bottom", {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.isValid, {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("margin-bottom");
  },
  enumerable: true,
  configurable: true
};
var marginLeft_export_definition;
marginLeft_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("margin", "left", {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.isValid, {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("margin-left");
  },
  enumerable: true,
  configurable: true
};
var marginRight_export_definition;
marginRight_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("margin", "right", {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.isValid, {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("margin-right");
  },
  enumerable: true,
  configurable: true
};
var marginTop_export_definition;
marginTop_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("margin", "top", {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.isValid, {
    definition: margin_export_definition,
    isValid: margin_export_isValid,
    parser: margin_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("margin-top");
  },
  enumerable: true,
  configurable: true
};
var opacity_export_definition;
opacity_export_definition = {
  set: function (v) {
    this._setProperty("opacity", external_dependency_parsers_0.parseNumber(v));
  },
  get: function () {
    return this.getPropertyValue("opacity");
  },
  enumerable: true,
  configurable: true
};
var outlineColor_export_definition;
outlineColor_export_definition = {
  set: function (v) {
    this._setProperty("outline-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("outline-color");
  },
  enumerable: true,
  configurable: true
};
var padding_export_definition, padding_export_isValid, padding_export_parser;
var padding_local_var_TYPES = external_dependency_parsers_0.TYPES;

var padding_local_var_isValid = function (v) {
  var type = external_dependency_parsers_0.valueType(v);
  return type === padding_local_var_TYPES.LENGTH || type === padding_local_var_TYPES.PERCENT || type === padding_local_var_TYPES.INTEGER && (v === "0" || v === 0);
};

var padding_local_var_parser = function (v) {
  return external_dependency_parsers_0.parseMeasurement(v);
};

var padding_local_var_mySetter = external_dependency_parsers_0.implicitSetter("padding", "", padding_local_var_isValid, padding_local_var_parser);
var padding_local_var_myGlobal = external_dependency_parsers_0.implicitSetter("padding", "", function () {
  return true;
}, function (v) {
  return v;
});
padding_export_definition = {
  set: function (v) {
    if (typeof v === "number") {
      v = String(v);
    }

    if (typeof v !== "string") {
      return;
    }

    var V = v.toLowerCase();

    switch (V) {
      case "inherit":
      case "initial":
      case "unset":
      case "":
        padding_local_var_myGlobal.call(this, V);
        break;

      default:
        padding_local_var_mySetter.call(this, v);
        break;
    }
  },
  get: function () {
    return this.getPropertyValue("padding");
  },
  enumerable: true,
  configurable: true
};
padding_export_isValid = padding_local_var_isValid;
padding_export_parser = padding_local_var_parser;
var paddingBottom_export_definition;
paddingBottom_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("padding", "bottom", {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.isValid, {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("padding-bottom");
  },
  enumerable: true,
  configurable: true
};
var paddingLeft_export_definition;
paddingLeft_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("padding", "left", {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.isValid, {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("padding-left");
  },
  enumerable: true,
  configurable: true
};
var paddingRight_export_definition;
paddingRight_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("padding", "right", {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.isValid, {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("padding-right");
  },
  enumerable: true,
  configurable: true
};
var paddingTop_export_definition;
paddingTop_export_definition = {
  set: external_dependency_parsers_0.subImplicitSetter("padding", "top", {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.isValid, {
    definition: padding_export_definition,
    isValid: padding_export_isValid,
    parser: padding_export_parser
  }.parser),
  get: function () {
    return this.getPropertyValue("padding-top");
  },
  enumerable: true,
  configurable: true
};
var right_export_definition;
right_export_definition = {
  set: function (v) {
    this._setProperty("right", external_dependency_parsers_0.parseMeasurement(v));
  },
  get: function () {
    return this.getPropertyValue("right");
  },
  enumerable: true,
  configurable: true
};
var stopColor_export_definition;
stopColor_export_definition = {
  set: function (v) {
    this._setProperty("stop-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("stop-color");
  },
  enumerable: true,
  configurable: true
};
var textLineThroughColor_export_definition;
textLineThroughColor_export_definition = {
  set: function (v) {
    this._setProperty("text-line-through-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("text-line-through-color");
  },
  enumerable: true,
  configurable: true
};
var textOverlineColor_export_definition;
textOverlineColor_export_definition = {
  set: function (v) {
    this._setProperty("text-overline-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("text-overline-color");
  },
  enumerable: true,
  configurable: true
};
var textUnderlineColor_export_definition;
textUnderlineColor_export_definition = {
  set: function (v) {
    this._setProperty("text-underline-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("text-underline-color");
  },
  enumerable: true,
  configurable: true
};
var top_export_definition;
top_export_definition = {
  set: function (v) {
    this._setProperty("top", external_dependency_parsers_0.parseMeasurement(v));
  },
  get: function () {
    return this.getPropertyValue("top");
  },
  enumerable: true,
  configurable: true
};
var webkitBorderAfterColor_export_definition;
webkitBorderAfterColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-border-after-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-border-after-color");
  },
  enumerable: true,
  configurable: true
};
var webkitBorderBeforeColor_export_definition;
webkitBorderBeforeColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-border-before-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-border-before-color");
  },
  enumerable: true,
  configurable: true
};
var webkitBorderEndColor_export_definition;
webkitBorderEndColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-border-end-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-border-end-color");
  },
  enumerable: true,
  configurable: true
};
var webkitBorderStartColor_export_definition;
webkitBorderStartColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-border-start-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-border-start-color");
  },
  enumerable: true,
  configurable: true
};
var webkitColumnRuleColor_export_definition;
webkitColumnRuleColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-column-rule-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-column-rule-color");
  },
  enumerable: true,
  configurable: true
};
var webkitMatchNearestMailBlockquoteColor_export_definition;
webkitMatchNearestMailBlockquoteColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-match-nearest-mail-blockquote-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-match-nearest-mail-blockquote-color");
  },
  enumerable: true,
  configurable: true
};
var webkitTapHighlightColor_export_definition;
webkitTapHighlightColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-tap-highlight-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-tap-highlight-color");
  },
  enumerable: true,
  configurable: true
};
var webkitTextEmphasisColor_export_definition;
webkitTextEmphasisColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-text-emphasis-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-text-emphasis-color");
  },
  enumerable: true,
  configurable: true
};
var webkitTextFillColor_export_definition;
webkitTextFillColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-text-fill-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-text-fill-color");
  },
  enumerable: true,
  configurable: true
};
var webkitTextStrokeColor_export_definition;
webkitTextStrokeColor_export_definition = {
  set: function (v) {
    this._setProperty("-webkit-text-stroke-color", external_dependency_parsers_0.parseColor(v));
  },
  get: function () {
    return this.getPropertyValue("-webkit-text-stroke-color");
  },
  enumerable: true,
  configurable: true
};
var width_export_definition;

function width_local_fn_parse(v) {
  if (String(v).toLowerCase() === "auto") {
    return "auto";
  }

  if (String(v).toLowerCase() === "inherit") {
    return "inherit";
  }

  return external_dependency_parsers_0.parseMeasurement(v);
}

width_export_definition = {
  set: function (v) {
    this._setProperty("width", width_local_fn_parse(v));
  },
  get: function () {
    return this.getPropertyValue("width");
  },
  enumerable: true,
  configurable: true
};

exports = function (prototype) {
  Object.defineProperties(prototype, {
    azimuth: azimuth_export_definition,
    backgroundColor: backgroundColor_export_definition,
    "background-color": backgroundColor_export_definition,
    backgroundImage: backgroundImage_export_definition,
    "background-image": backgroundImage_export_definition,
    backgroundRepeat: backgroundRepeat_export_definition,
    "background-repeat": backgroundRepeat_export_definition,
    backgroundAttachment: backgroundAttachment_export_definition,
    "background-attachment": backgroundAttachment_export_definition,
    backgroundPosition: backgroundPosition_export_definition,
    "background-position": backgroundPosition_export_definition,
    background: background_export_definition,
    borderWidth: borderWidth_export_definition,
    "border-width": borderWidth_export_definition,
    borderStyle: borderStyle_export_definition,
    "border-style": borderStyle_export_definition,
    borderColor: borderColor_export_definition,
    "border-color": borderColor_export_definition,
    border: border_export_definition,
    borderBottomWidth: borderBottomWidth_export_definition,
    "border-bottom-width": borderBottomWidth_export_definition,
    borderBottomStyle: borderBottomStyle_export_definition,
    "border-bottom-style": borderBottomStyle_export_definition,
    borderBottomColor: borderBottomColor_export_definition,
    "border-bottom-color": borderBottomColor_export_definition,
    borderBottom: borderBottom_export_definition,
    "border-bottom": borderBottom_export_definition,
    borderCollapse: borderCollapse_export_definition,
    "border-collapse": borderCollapse_export_definition,
    borderLeftWidth: borderLeftWidth_export_definition,
    "border-left-width": borderLeftWidth_export_definition,
    borderLeftStyle: borderLeftStyle_export_definition,
    "border-left-style": borderLeftStyle_export_definition,
    borderLeftColor: borderLeftColor_export_definition,
    "border-left-color": borderLeftColor_export_definition,
    borderLeft: borderLeft_export_definition,
    "border-left": borderLeft_export_definition,
    borderRightWidth: borderRightWidth_export_definition,
    "border-right-width": borderRightWidth_export_definition,
    borderRightStyle: borderRightStyle_export_definition,
    "border-right-style": borderRightStyle_export_definition,
    borderRightColor: borderRightColor_export_definition,
    "border-right-color": borderRightColor_export_definition,
    borderRight: borderRight_export_definition,
    "border-right": borderRight_export_definition,
    borderSpacing: borderSpacing_export_definition,
    "border-spacing": borderSpacing_export_definition,
    borderTopWidth: borderTopWidth_export_definition,
    "border-top-width": borderTopWidth_export_definition,
    borderTopStyle: borderTopStyle_export_definition,
    "border-top-style": borderTopStyle_export_definition,
    borderTopColor: borderTopColor_export_definition,
    "border-top-color": borderTopColor_export_definition,
    borderTop: borderTop_export_definition,
    "border-top": borderTop_export_definition,
    bottom: bottom_export_definition,
    clear: clear_export_definition,
    clip: clip_export_definition,
    color: color_export_definition,
    cssFloat: cssFloat_export_definition,
    "css-float": cssFloat_export_definition,
    flexGrow: flexGrow_export_definition,
    "flex-grow": flexGrow_export_definition,
    flexShrink: flexShrink_export_definition,
    "flex-shrink": flexShrink_export_definition,
    flexBasis: flexBasis_export_definition,
    "flex-basis": flexBasis_export_definition,
    flex: flex_export_definition,
    float: float_export_definition,
    floodColor: floodColor_export_definition,
    "flood-color": floodColor_export_definition,
    fontFamily: fontFamily_export_definition,
    "font-family": fontFamily_export_definition,
    fontSize: fontSize_export_definition,
    "font-size": fontSize_export_definition,
    fontStyle: fontStyle_export_definition,
    "font-style": fontStyle_export_definition,
    fontVariant: fontVariant_export_definition,
    "font-variant": fontVariant_export_definition,
    fontWeight: fontWeight_export_definition,
    "font-weight": fontWeight_export_definition,
    lineHeight: lineHeight_export_definition,
    "line-height": lineHeight_export_definition,
    font: font_export_definition,
    height: height_export_definition,
    left: left_export_definition,
    lightingColor: lightingColor_export_definition,
    "lighting-color": lightingColor_export_definition,
    margin: margin_export_definition,
    marginBottom: marginBottom_export_definition,
    "margin-bottom": marginBottom_export_definition,
    marginLeft: marginLeft_export_definition,
    "margin-left": marginLeft_export_definition,
    marginRight: marginRight_export_definition,
    "margin-right": marginRight_export_definition,
    marginTop: marginTop_export_definition,
    "margin-top": marginTop_export_definition,
    opacity: opacity_export_definition,
    outlineColor: outlineColor_export_definition,
    "outline-color": outlineColor_export_definition,
    padding: padding_export_definition,
    paddingBottom: paddingBottom_export_definition,
    "padding-bottom": paddingBottom_export_definition,
    paddingLeft: paddingLeft_export_definition,
    "padding-left": paddingLeft_export_definition,
    paddingRight: paddingRight_export_definition,
    "padding-right": paddingRight_export_definition,
    paddingTop: paddingTop_export_definition,
    "padding-top": paddingTop_export_definition,
    right: right_export_definition,
    stopColor: stopColor_export_definition,
    "stop-color": stopColor_export_definition,
    textLineThroughColor: textLineThroughColor_export_definition,
    "text-line-through-color": textLineThroughColor_export_definition,
    textOverlineColor: textOverlineColor_export_definition,
    "text-overline-color": textOverlineColor_export_definition,
    textUnderlineColor: textUnderlineColor_export_definition,
    "text-underline-color": textUnderlineColor_export_definition,
    top: top_export_definition,
    webkitBorderAfterColor: webkitBorderAfterColor_export_definition,
    "webkit-border-after-color": webkitBorderAfterColor_export_definition,
    webkitBorderBeforeColor: webkitBorderBeforeColor_export_definition,
    "webkit-border-before-color": webkitBorderBeforeColor_export_definition,
    webkitBorderEndColor: webkitBorderEndColor_export_definition,
    "webkit-border-end-color": webkitBorderEndColor_export_definition,
    webkitBorderStartColor: webkitBorderStartColor_export_definition,
    "webkit-border-start-color": webkitBorderStartColor_export_definition,
    webkitColumnRuleColor: webkitColumnRuleColor_export_definition,
    "webkit-column-rule-color": webkitColumnRuleColor_export_definition,
    webkitMatchNearestMailBlockquoteColor: webkitMatchNearestMailBlockquoteColor_export_definition,
    "webkit-match-nearest-mail-blockquote-color": webkitMatchNearestMailBlockquoteColor_export_definition,
    webkitTapHighlightColor: webkitTapHighlightColor_export_definition,
    "webkit-tap-highlight-color": webkitTapHighlightColor_export_definition,
    webkitTextEmphasisColor: webkitTextEmphasisColor_export_definition,
    "webkit-text-emphasis-color": webkitTextEmphasisColor_export_definition,
    webkitTextFillColor: webkitTextFillColor_export_definition,
    "webkit-text-fill-color": webkitTextFillColor_export_definition,
    webkitTextStrokeColor: webkitTextStrokeColor_export_definition,
    "webkit-text-stroke-color": webkitTextStrokeColor_export_definition,
    width: width_export_definition
  });
};

export default exports;